import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "~/components/DetailsPage/components";
import { CveRiskFactors } from "~/components/vulnerabilities/cve-risk-factors";
import { useFindingOnAsset } from "../../hooks";
import { useParams } from "react-router-dom";
import {
  isAdvisoryOrVulnerabilityFinding,
  isCheckFinding,
} from "~/pages/findings/FindingOnAsset/utils";
import { EmptySection } from "~/components/vulnerabilities";

export function VulnerabilityInsights() {
  const { finding, riskFactorsWithDocs } = useFindingOnAsset();
  const { vulnId } = useParams();

  return (
    <Fragment>
      <SectionHeading
        heading={vulnId ? "Vulnerability insights" : "Risk assessment"}
      />
      {/* <Typography className="summary-text" sx={{ mb: 3 }}>
     placeholder for vulnerability summary text
      </Typography> */}
      {(isCheckFinding(finding) && riskFactorsWithDocs.length === 0) ||
      (isAdvisoryOrVulnerabilityFinding(finding) &&
        !finding?.cvss &&
        !finding?.epss &&
        riskFactorsWithDocs.length === 0) ? (
        <EmptySection
          id="no-risk-factors"
          text={
            isAdvisoryOrVulnerabilityFinding(finding)
              ? `There is currently no ${vulnId ? "vulnerability insights" : "risk assessment"} available.`
              : "There is currently no risk assessment available."
          }
        />
      ) : (
        <CveRiskFactors
          id={`${vulnId ? "cve" : "advisory"}-risk-factors`}
          cvssScore={
            isAdvisoryOrVulnerabilityFinding(finding) ? finding?.cvss : null
          }
          epssScore={
            isAdvisoryOrVulnerabilityFinding(finding) ? finding?.epss : null
          }
          riskFactors={riskFactorsWithDocs}
          RiskFactorsProps={{ hideValues: true }}
          CVSSProps={{ flipContent: true }}
          EPSSProps={{ flipContent: true }}
        />
      )}
    </Fragment>
  );
}
