import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, Chip, TableCell, Typography } from "@mui/material";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { Icon_Ids, ScoreState, Tag } from "~/operations";
import { MoonIcon } from "~/components/ui-library";
import { AdvisoryIcon, BlockIcon } from "~/components/icons";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";

type FindingTitleCellProps = {
  id: string;
  title: string;
  tags?: Array<Tag>;
  state?: ScoreState;
  isPendingException?: boolean;
  onPendingExceptionClick?: () => void;
  "data-testid"?: string;
  iconId?: Icon_Ids | null | undefined;
};

export const TitleCell = ({
  state = ScoreState.Open,
  isPendingException = false,
  onPendingExceptionClick,
  id,
  title,
  tags = [],
  iconId,
  ...props
}: FindingTitleCellProps) => {
  const ExceptionChip = () => {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        {state === ScoreState.Snoozed && (
          <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
        )}
        {state === ScoreState.Disabled && (
          <Chip label="Disabled" icon={<BlockIcon />} size="small" />
        )}
        {isPendingException && (
          <NavigateToExceptionButton
            onClick={(e) => {
              e.stopPropagation();
              onPendingExceptionClick?.();
            }}
          />
        )}
      </Box>
    );
  };

  const hasException =
    [ScoreState.Snoozed, ScoreState.Disabled].includes(state) ||
    isPendingException;

  return (
    <TableCell data-testid={props["data-testid"]}>
      <OverflowHelper>
        <Typography
          fontSize="14px"
          data-testid="finding-id"
          gap={1}
          display="inline-flex"
          alignItems="center"
        >
          {hasException && <ExceptionChip />}
          {iconId && <DynamicIcon iconId={iconId} defaultIcon={AdvisoryIcon} />}
          {id && (
            <Typography fontSize="14px" fontWeight="bold" variant="caption">
              {id}:{" "}
            </Typography>
          )}
          {title}
        </Typography>
      </OverflowHelper>
      {tags.length > 0 && (
        <Box display="flex" gap={1} mt={1}>
          {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      )}
    </TableCell>
  );
};
