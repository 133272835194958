import { selectUser, users } from "./development-provider";

// FeatureName type ensures the correct feature name
// identifiers are used everywhere. Also allows autocompletion.
type FeatureName =
  | "Developer Tools"
  | "Hosted AWS - Role Auth"
  | "Assets Exceptions"
  | "Asset Explorer"
  | "Asset Resources"
  | "CVE Remediation"
  | "Reporting"
  | "Workload Identity"
  | "Space level exceptions"
  | "Asset Check Details Page"
  | "Remediation V2";

export interface FeatureFlag {
  name: FeatureName;
  enabled: boolean;
  description?: string;
}

export const features: FeatureFlag[] = [
  {
    name: "Developer Tools",
    enabled: false,
    description: "Additional tools for debugging/discovering",
  },
  {
    name: "Hosted AWS - Role Auth",
    enabled: false,
    description:
      "Gain access to use the new Hosted AWS integration using Role Authentication.",
  },
  {
    name: "Assets Exceptions",
    enabled: false,
    description: "Enabling setting exceptions for controls -> checks -> assets",
  },
  {
    name: "Reporting",
    enabled: false,
    description: "Enable the generating PDF reports",
  },
  {
    name: "Asset Explorer",
    enabled: false,
    description: "Enable the Explorer tab in the Asset page.",
  },
  {
    name: "Asset Resources",
    enabled: false,
    description: "Enable the Resources tab in the Asset page.",
  },
  {
    name: "CVE Remediation",
    enabled: false,
    description: "Providing Remediation on asset and space level CVEs",
  },
  {
    name: "Workload Identity",
    enabled: false,
    description: "Enable Workload Identity Federation flow.",
  },
  {
    name: "Space level exceptions",
    enabled: false,
    description:
      "Enables setting exceptions on space level cves, advisories, checks",
  },
  {
    name: "Asset Check Details Page",
    enabled: false,
    description: "Enables Asset Check Details Page.",
  },
  {
    name: "Remediation V2",
    enabled: false,
    description: "Enables Remediation V2",
  },
];

export const isFeatureEnabled = (name: FeatureName): boolean => {
  const feature = features.find((f) => f.name === name);
  return feature ? feature.enabled : false;
};

const storedFeatures: FeatureFlag[] = JSON.parse(
  localStorage.getItem("features") || "[]",
);
storedFeatures.forEach((sf) => {
  const feature = features.find((f) => f.name === sf.name);
  if (feature) {
    feature.enabled = sf.enabled;
  }
});

// console user manager
export class CliManager {
  users() {
    console.table(users);
  }

  su(token: string) {
    const result = users.find((usr) => usr.token === token);
    selectUser(result);
  }

  features() {
    console.table(features);
  }

  enableFeature(name: FeatureName) {
    this.setFeature(name, true);
  }

  disableFeature(name: FeatureName) {
    this.setFeature(name, false);
  }

  setFeature(name: FeatureName, enabled: boolean) {
    const feature = features.find((f) => f.name === name);
    if (feature) {
      feature.enabled = enabled;
      localStorage.setItem("features", JSON.stringify(features));
      location.reload();
    }
  }
}

// register user manager globally
window.cli = new CliManager();
